/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { TextureType as t, PixelFormat as i, PixelType as h, TextureSamplingMode as s, TextureWrapMode as a } from "./enums.js";
import { getBytesPerElementFormat as e } from "./Util.js";
class p {
  constructor(e = 0, p = e) {
    this.width = e, this.height = p, this.target = t.TEXTURE_2D, this.pixelFormat = i.RGBA, this.dataType = h.UNSIGNED_BYTE, this.samplingMode = s.LINEAR, this.wrapMode = a.REPEAT, this.maxAnisotropy = 1, this.flipped = !1, this.hasMipmap = !1, this.isOpaque = !1, this.unpackAlignment = 4, this.preMultiplyAlpha = !1, this.depth = 1, this.isImmutable = !1;
  }
}
function r(i) {
  return i.width <= 0 || i.height <= 0 ? 0 : Math.round(i.width * i.height * (i.hasMipmap ? 4 / 3 : 1) * (null == i.internalFormat ? 4 : e(i.internalFormat)) * (i.target === t.TEXTURE_CUBE_MAP ? 6 : 1));
}
export { p as TextureDescriptor, r as estimateMemory };