/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import has from "./core/has.js";
import { addQueryParameter as o } from "./core/urlUtils.js";
import { buildDate as e, commitHash as r } from "./support/revision.js";
Symbol.dispose ??= Symbol("Symbol.dispose"), Symbol.asyncDispose ??= Symbol("Symbol.asyncDispose");
const s = "4.31";
let i,
  t = s;
t = "4.31.6";
function n(o) {
  i = o;
}
function l(e) {
  const r = i?.findCredential(e);
  return r?.token ? o(e, "token", r.token) : e;
}
has("host-webworker") || globalThis.$arcgis || Object.defineProperty(globalThis, "$arcgis", {
  configurable: !1,
  enumerable: !0,
  writable: !1,
  value: {}
}), has("host-webworker");
export { l as addTokenParameter, e as buildDate, t as fullVersion, i as id, r as revision, n as setId, s as version };